<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="cuisineMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
             <feather-icon icon="SkipBackIcon" /> {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row> 
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group :label="$t('Cuisine Master List.Cuisine Name')">
              <validation-provider
                #default="{ errors }"
                name="Cuisine Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.cuisine_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :disabled="!$can('write', 'masters')"
                  :placeholder="$t('Cuisine Master List.Cuisine Name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Cuisine Master List.Cuisine Code')">
              <validation-provider
                #default="{ errors }"
                name="Cuisine Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.cuisine_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Cuisine Master List.Cuisine Code')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group :label="$t('Cuisine Master List.Cuisine Description')">
              <validation-provider
                #default="{ errors }"
                name="Cuisine Description"
                rules=""
              >
                <b-form-textarea
                  v-model="form.cuisine_desc"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Cuisine Master List.Cuisine Description')"
                  :disabled="!$can('write', 'masters')"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              variant="primary" 
              type="submit"
              @click.prevent="saveCuisine"
            >
              {{ $t("Common.Save") }} 
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import MasterServices from "@/apiServices/MasterServices";

export default {
  name: "form",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      cuisine_id: null,
      form: {
        cuisine_name: "",
        cuisine_code: "",
        cuisine_desc: "",
        cuisine_is_active: true,
      },
      required,
    };
  },
  methods: {
    saveCuisine() {
      this.$refs.cuisineMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.cuisine_id = this.cuisine_id;
            // console.log("this.form=>",this.form);return false;
            const cuisineMaster = await MasterServices.saveCuisine(
              this.form
            );

            if (cuisineMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    cuisineMaster.data.message || "Cuisine Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/masters/cuisine-list");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: cuisineMaster.data.message || "Cannot Added Cuisine",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveCuisine ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveCuisine",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleCuisine(cuisine_id) {
      try {
        const response = await MasterServices.getSingleCuisine(cuisine_id);
        let singleCuisine = response.data.data;
        if (response.data.status) {
          this.form = {
            cuisine_name: singleCuisine.cuisine_name,
            cuisine_code: singleCuisine.cuisine_code,
            cuisine_desc: singleCuisine.cuisine_desc,
            cuisine_is_active: singleCuisine.cuisine_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    if (
      this.$route.params.cuisine_id &&
      !isNaN(this.$route.params.cuisine_id)
    ) {
      this.cuisine_id = this.$route.params.cuisine_id;
      this.getSingleCuisine(this.cuisine_id);
    }
  },
};
</script>

<style></style>
